import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

export default class NotFound extends React.Component {
  componentDidMount() {
    // We only need to navigate back to the previous url if it's provided in the previous page. 
    const previousUrl = localStorage.getItem('previousUrl'); 

    if (!previousUrl){
      return; 
    }

    // Navigate back to the previous url after 3 minutes. 
    setTimeout(() => {
      console.log('Timer elapsed, now navigating back to where we came from.')
      window.location.href = previousUrl; 
    }, 10000);
  }

  render() {
    return (
      <Layout>
      <h1>Page not found, going back to home in 10 seconds.</h1>
      <p>
        <Link to="/">Head home</Link>
      </p>
    </Layout>
    )
  }
}